<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:navigableDetail="false"
			:showDeleteBtn="false"
			:showCreateBtn="false"
		></pui-datatable>
		<notifications-modals :modelName="modelName"></notifications-modals>
	</div>
</template>

<script>
import notificationsActions from './NotificationsActions';
import notificationsModals from './NotificationsModals.vue';

export default {
	name: 'notifications-grid',
	components: {
		'notifications-modals': notificationsModals
	},
	data() {
		return {
			modelName: 'notifications',
			actions: notificationsActions.gridactions,
			modelColumnDefs: {
				linkmodel: {
					createdCell: (td, cellData, rowData) => {
						const model = rowData.linkmodel;
						const pk = this.$puiUtils.convertPkStringToPkObject(this.$store.getters.getModelByName(rowData.model), rowData.pk);
						const base64Pk = this.$puiUtils.utf8ToB64(JSON.stringify(pk));

						var template = document.createElement('template');
						template.innerHTML =
							'<span style="cursor: pointer;"><i class="fa-kit fa-solid-visualize" style="font-size: 17px; color:#A1A1A1"></i></span>';
						let redirectIcon = template.content.firstChild;

						if (rowData.linktogrid) {
							redirectIcon.addEventListener('click', () => this.goToModelGrid(model));
						} else {
							redirectIcon.addEventListener('click', () => this.goToModelRead(model, base64Pk));
						}

						td.innerHTML = null;
						td.appendChild(redirectIcon);
					}
				}
			}
		};
	},
	methods: {
		goToModelGrid(model) {
			const params = {
				router: this.$router,
				url: model,
				params: null,
				query: null
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
		},
		goToModelUpdate(model, base64Pk) {
			const params = {
				router: this.$router,
				url: this.$store.getters.getModelByName(model).url.update + '/' + base64Pk,
				params: null,
				query: null
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
		},
		goToModelRead(model, base64Pk) {
			const params = {
				router: this.$router,
				url: model + '/read/' + base64Pk + '/',
				params: null,
				query: null
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
		}
	}
};
</script>
