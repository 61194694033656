const modifNotifAction = {
	id: 'modifNotifAction',
	selectionType: 'multiple',
	label: 'modifnotif.label',
	functionality: 'WRITE_NOTIFICATIONS',
	checkAvailability: function (registries) {
		return registries && registries.archived === false;
	},
	runAction: async function (action, model, registries) {
		const row = {
			registries: registries.map((notif) => ({ id: notif.id })),
			isAction: true
		};

		this.$puiEvents.$emit('pui-modalDialogForm-modifNotif-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [modifNotifAction]
};
