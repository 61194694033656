<template>
	<div>
		<pui-modal-dialog-form
			:overflow="false"
			:titleText="$t('modifnotif.action')"
			:modelName="modelName"
			:dialogName="dialogNameModifNotif"
			:widthDialog="800"
			:onOk="onOkModifNotif"
		>
			<template slot="message">
				<v-container>{{ $t('portcalls1.text') }}</v-container>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>

export default {
	name: 'notificationsmodals',
	data() {
		return {
			dialogNameModifNotif: 'modifNotif'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},

	methods: {
		subscribeToModalDialogEvent() {
			this.$puiEvents.$on(`pui-modalDialog-modifNotif-${this.modelName}-ok`, () => {
				if (this.$refs.validateDisable.validate()) {
					this.setShowmodifnotif(false);
					this.$puiEvents.$emit('pui-action-runAction-' + this.modelName);
				}
			});
			this.$puiEvents.$on(`pui-modalDialog-modifNotif-${this.modelName}-cancel`, () => {
				this.setShowmodifnotif(false);
				this.$puiEvents.$off('pui-action-runAction-' + this.modelName);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
		},
		setShowmodifnotif(action) {
			this.modal.modifnotif.show = action;
		},

		onOkModifNotif(modalData) {
			const urlController = this.$store.getters.getModelByName(this.modelName).url.archive;

			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t('modifnotif.title');

			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					urlController,
					modalData.registries,
					() => {
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
						const message = this.$puiI18n.t('puiaction.notifySuccess');
						this.$puiNotify.success(message, title);
						resolve(true);
					},
					(e) => {
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					}
				);
			});
		}
	}
};
</script>
